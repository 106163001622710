import React from "react";
import { isEmpty } from "lodash";
import Search from "../../components/home/search";
import { Layout } from "../../../../src/components";

const ArchivePage = (props) => {
  const {
    pageContext: {
      categories,
      category,
      category: { name, seo, uri },
      postSearchData: { products, options },
    },
  } = props;

  return (
    <Layout pageTitle={name} seo={seo}>
      <div className="layout">
        {!isEmpty(props.pageContext) ? (
          <Search
            products={products}
            initialProducts={category.products.nodes}
            engine={options}
            category={category}
            categories={categories}
          />
        ) : (
          <div>Something went wrong</div>
        )}
      </div>
    </Layout>
  );
};
export default ArchivePage;
